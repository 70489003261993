<template>
  <m-widget-container
    v-if="current && forecast"
    class="flex-shrink-1"
    :style="`--m-weather-background-light-color: ${backgroundLightColor};`"
    height="100%"
  >
    <v-row no-gutters justify="center" class="fill-height flex-nowrap">
      <v-row no-gutters class="flex-nowrap" style="max-width: 50%">
        <v-col class="d-flex flex-column align-center justify-center text-center">
          <v-icon :class="`display-${ title ? '2' : '3' }`">
            {{ ICONS[current.icon] }}
          </v-icon>
          <span>
            {{ title }}
          </span>
        </v-col>
        <v-col class="d-flex flex-column align-center justify-center text-center">
          <span class="display-1">
            {{ current.temperature | formatTemp }}
          </span>
          <span class="caption" style="line-height: 1em;">
            {{ current.summary }}
          </span>
        </v-col>
      </v-row>
      <v-row no-gutters class="flex-nowrap">
        <v-col
          v-for="day in forecast"
          :key="day.time"
          class="m-weather--day grow d-flex flex-column justify-space-around align-stretch text-center"
        >
          <span class="body-2">
            {{ day.day }}
          </span>
          <v-icon class="display-1">
            {{ ICONS[day.icon] }}
          </v-icon>
          <div class="d-flex align-stretch justify-space-around px-2">
            <v-btn text :color="hotColor" class="padless caption">
              {{ day.temperatureHigh | formatTemp }}
            </v-btn>
            <v-btn text :color="coolColor" class="padless caption">
              {{ day.temperatureLow | formatTemp }}
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-row>
  </m-widget-container>
</template>
<script>
import axios from 'axios'
import { mWidgetMixin } from '../../../mixins'
const Color = require('color')
export default {
  name: 'MWeather',
  filters: {
    formatTemp(value) {
      return `${Math.round(value)}°C`
    }
  },
  mixins: [mWidgetMixin],
  props: {
    title: {
      type: String,
      default: () => ''
    },
    coords: {
      type: Array,
      default: () => []
    },
    backgroundColor: {
      type: String,
      default: () => 'primary'
    },
    hotColor: {
      type: String,
      default: () => 'red lighten-3'
    },
    coolColor: {
      type: String,
      default: () => 'blue lighten-3'
    }
  },
  data: () => ({
    latitude: undefined,
    longitude: undefined,
    ICONS: {
      'clear-day': 'mdi-weather-sunny',
      'clear-night': 'mdi-weather-night',
      rain: 'mdi-weather-pouring',
      snow: 'mdi-snowy-heavy',
      sleet: 'mdi-hail',
      wind: 'mdi-weather-windy',
      fog: 'mdi-weather-fog',
      cloudy: 'mdi-weather-cloudy',
      'partly-cloudy-day': 'mdi-weather-partly-cloudy',
      'partly-cloudy-night': 'mdi-weather-night-partly-cloudy'
    },
    current: undefined,
    forecast: undefined
  }),
  computed: {
    backgroundLightColor() {
      if (!this.backgroundColor) {
        return ''
      }
      console.log('theme', this.$vuetify.theme.themes.light)
      console.log('color', this.backgroundColor)
      const getColor = this.getColor(this.$vuetify.theme.themes.light, this.backgroundColor)
      console.log(getColor)
      const color = Color(getColor)
      return color.lighten(0.07)
    }
  },
  watch: {
    coords: {
      handler(coords) {
        if (coords && coords.length === 2) {
          const [longitude, latitude] = coords
          this.latitude = latitude
          this.longitude = longitude
          this.getCurrent()
          this.getForecast()
        }
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async getCurrent() {
      const { data } = await axios.get(`https://europe-west1-mammutos-7ad07.cloudfunctions.net/darkskyCurrent?lat=${this.latitude}&lon=${this.longitude}`)
      this.current = data
    },
    async getForecast() {
      const { data } = await axios.get(`https://europe-west1-mammutos-7ad07.cloudfunctions.net/darkskyForecast?lat=${this.latitude}&lon=${this.longitude}`)
      this.forecast = data.daily.data.slice(0, 7)
    }
  }
}
</script>

<style lang="sass">
  .m-weather--day:nth-child(odd)
    background-color: var(--m-weather-background-light-color)
  .v-btn.padless
    padding: 0 !important
    height: auto !important
    min-width: 2.5em !important
</style>
