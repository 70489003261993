export const mWidgetMixin = {
  props: {
    backgroundColor: {
      type: String,
      default: () => null
    },
    dark: {
      type: Boolean,
      default: () => false
    },
    widgetContainerFlat: {
      type: Boolean,
      default: () => false
    },
    widgetContainerTile: {
      type: Boolean,
      default: () => true
    },
    widgetContainerPadding: {
      type: [Number, String],
      default: () => 0
    },
    widgetContainerMargin: {
      type: [Number, String],
      default: () => 0
    },
    widgetContainerHeight: {
      type: [Number, String],
      default: () => '100%'
    },
    widgetContainerWidth: {
      type: [Number, String],
      default: () => '100%'
    }
  },
  computed: {
    widgetContainerClass() {
      const classes = []
      classes.push(this.widgetContainerPadding || 'pa-0')
      this.widgetContainerMargin && classes.push(this.widgetContainerMargin)
      return classes
    }
  }
}
